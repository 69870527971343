import styled from "styled-components";

const H1 = styled.h1`
  color: black;
  font-family: "Montserrat";
  font-weight: lighter;
  font-size: 2.5rem;
`;

export default H1;
